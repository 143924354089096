@import "@/assets/scss/var.scss";




































































































































































































































.form-page {
	display: flex;
	justify-content: center;
	flex: 1;
	// align-items: center;
	// background: #f8f8f8;
	.hv-form {
		// max-width: 450px;
		width: 540px;
		border: 1px solid #e4e4e4;
		margin: 20px;
	}
	.form-result {
		display: flex;
		flex-flow: column;
		/* justify-content: center; */
		align-items: center;
		font-size: 14px;
		background: #fff;
		padding: 20px;
		margin-top: 10%;
	}
}
